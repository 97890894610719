import useAuth from "../Hooks/useAuth";
import { Navigate, useLocation } from "react-router-dom";
import { Urls } from "../URLs";

const AuthGuard = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const { pathname } = useLocation();

  if (isAuthenticated) return <>{children}</>;

  return <Navigate replace to={Urls.Signin} state={{ from: pathname }} />;
};

export default AuthGuard;
