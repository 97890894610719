import { memo, useState } from "react";
//import { Link } from "react-router-dom";
import {
  Avatar,
  IconButton,
  MenuItem,
  useMediaQuery,
  Box,
  styled,
  useTheme,
} from "@mui/material";
import { LockReset, Menu, PowerSettingsNew } from "@mui/icons-material";

import MatxMenu from "../../MatxMenu";
import { themeShadows } from "../../MatxTheme/themeColors";
//import { NotificationProvider } from "../../../Contexts/NotificationContext";
import useSettings from "../../../Hooks/useSettings";
import { topBarHeight } from "../../../Utils/constant";

import { Span } from "../../Typography";
import NotificationBar from "../../NotificationBar/NotificationBar";
import Axios from "../../../Axios";
import { Api } from "../../../Api";
import FormDialog from "../../../Views/dialog/FormDialog";

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

const TopbarRoot = styled("div")({
  top: 0,
  zIndex: 96,
  height: topBarHeight,
  boxShadow: themeShadows[8],
  transition: "all 0.3s ease",
});

const TopbarContainer = styled(Box)(({ theme }) => ({
  padding: "8px",
  paddingLeft: 18,
  paddingRight: 20,
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  background: theme.palette.primary.main,
  [theme.breakpoints.down("sm")]: {
    paddingLeft: 16,
    paddingRight: 16,
  },
  [theme.breakpoints.down("xs")]: {
    paddingLeft: 14,
    paddingRight: 16,
  },
}));

const UserMenu = styled(Box)({
  padding: 4,
  display: "flex",
  borderRadius: 24,
  cursor: "pointer",
  alignItems: "center",
  "& span": { margin: "0 8px" },
});

const StyledItem = styled(MenuItem)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  minWidth: 185,
  "& a": {
    width: "100%",
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
  },
  "& span": { marginRight: "10px", color: theme.palette.text.primary },
}));

const Layout1Topbar = () => {
  const theme = useTheme();
  const { settings, updateSettings } = useSettings();
  let userName =
    localStorage.getItem("userName") === "undefined"
      ? "Admin"
      : localStorage.getItem("userName");
  const isMdScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [OpenFormDialog, setOpenFormDialog] = useState(false);
  const [SelectedData, setSelectedData] = useState({});

  const updateSidebarMode = (sidebarSettings) => {
    updateSettings({
      layout1Settings: { leftSidebar: { ...sidebarSettings } },
    });
  };

  const handleSidebarToggle = () => {
    let { layout1Settings } = settings;
    let mode;
    if (isMdScreen) {
      mode = layout1Settings.leftSidebar.mode === "close" ? "mobile" : "close";
    } else {
      mode = layout1Settings.leftSidebar.mode === "full" ? "close" : "full";
    }
    updateSidebarMode({ mode });
  };
  function logout() {
    Axios.get(Api.Logout)
      .then((res) => {
        window.localStorage.clear();
        window.location.href = "/";
      })
      .catch((err) => {
        console.error(err);
      });
  }
  const ChangePassInputs = [
    {
      name: "newPassword",
      type: "password",
      label: "New Password",
      validators: ["required"],
      errorMessages: ["this field is required"],
    },
  ];
  return (
    <Box>
      <TopbarRoot>
        <TopbarContainer>
          <Box display="flex">
            <StyledIconButton onClick={handleSidebarToggle}>
              <Menu />
            </StyledIconButton>
          </Box>

          <Box display="flex" alignItems="center">
            {/* <MatxSearchBox /> */}

            {/* <NotificationProvider> */}
            <NotificationBar />
            {/* </NotificationProvider> */}

            <MatxMenu
              menuButton={
                <UserMenu>
                  {/* <Hidden xsDown>
                    <Span>
                      Hi <strong>{userName}</strong>
                    </Span>
                  </Hidden> */}
                  <Avatar
                    src={userName}
                    sx={{ cursor: "pointer", width: "30px", height: "30px" }}
                  />
                </UserMenu>
              }
            >
              <StyledItem onClick={() => setOpenFormDialog(true)}>
                <LockReset />
                <Span sx={{ marginLeft: "5px" }}> Change password </Span>
              </StyledItem>

              <StyledItem onClick={logout}>
                <PowerSettingsNew />
                <Span sx={{ marginLeft: "5px" }}> Logout </Span>
              </StyledItem>
            </MatxMenu>
          </Box>
        </TopbarContainer>
        <FormDialog
          open={OpenFormDialog}
          setOpen={setOpenFormDialog}
          inputs={ChangePassInputs}
          url={Api.ChangePassword}
          selectedData={SelectedData}
          setSelectedData={setSelectedData}
          title="Change password"
          saveLabel="Submit"
          method="POST"
          payload={{
            newPassword: SelectedData?.newPassword,
          }}
          submitStyleBtn={{ backgroundColor: "#1976d2", color: "#ffffff" }}
        />
      </TopbarRoot>
    </Box>
  );
};

export default memo(Layout1Topbar);
