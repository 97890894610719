import React, { useState } from "react";
import {
  Alert,
  Box,
  Snackbar,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  Tooltip,
  ToggleButton,
} from "@mui/material";
import { ValidatorForm } from "react-material-ui-form-validator";
import { LoadingButton } from "@mui/lab";
import Axios from "../../Axios";
import { ExtractErrorMessage } from "../../Utils/utils";
import Inputs from "../Inputs";
import { CopyAll } from "@mui/icons-material";

export default function FormDialog({
  fullWidth = true,
  maxWidth = "sm",
  open,
  setOpen,
  actionName,
  title = "",
  content = "",
  selectedData = {},
  setSelectedData,
  inputs = [],
  gridLabel,
  gridInput,
  saveLabel,
  method,
  url,
  payload,
  ReqHeader,
  setRefresh,
  Refresh,
  onSave,
  onSubmit,
  onSuccess,
  submitStyleBtn,
  onCancel,
  onFail,
  onDefault,
  hideSaveBtn,
  disabledSave,
  showCustomInfo,
  CustomInfo,
}) {
  //#region state
  const [OpenAlertMessage, setOpenAlertMessage] = useState(false);
  const [AlertInfo, setAlertInfo] = useState({
    severity: "success",
    message: "",
  });
  const [loading, setloading] = useState(false);
  const [OpenTooltip, setOpenTooltip] = useState(false);
  const [selectedInput, setSelectedInput] = useState("");
  //#endregion state
  //#region functions
  const handleClose = () => {
    if (actionName) {
      setOpen((prev) => ({
        ...prev,
        [actionName]: false,
      }));
    } else {
      setOpen(false);
    }
    setloading(false);
    if (typeof setSelectedData === "function") {
      setSelectedData({});
    }
    if (typeof onCancel === "function") {
      onCancel();
    }
  };
  const handleChecked = (event) => {
    if (typeof event.persist === "function") {
      event.persist();
    }
    console.log(event);
    setSelectedData({
      ...selectedData,
      [event.target.name]: event.target.checked,
    });
  };
  const handelChangeAutoComplete = (event, newValue, name) => {
    setSelectedData({
      ...selectedData,
      [name]: newValue,
    });
  };
  const handleChange = (event) => {
    if (typeof event.persist === "function") {
      event.persist();
    }
    const { name, value } = event.target;
    setSelectedData({
      ...selectedData,
      [name]: value,
    });
  };
  function handelResponse(res) {
    if (typeof onSubmit === "function") {
      onSubmit();
    }
    const { detail, severity, isReqSuccess } = ExtractErrorMessage(res?.data);
    setAlertInfo({
      severity: severity,
      message: detail?.map((message) => message),
    });
    if (isReqSuccess) {
      handleClose();
      setRefresh(!Refresh);

      if (typeof onSuccess === "function") {
        onSuccess(res);
      }
    } else {
      setloading(false);
      if (typeof onFail === "function") {
        onFail();
      }
    }
    setOpenAlertMessage(true);
  }
  const handleSave = () => {
    setloading(true);
    if (typeof onSave === "function") {
      onSave();
    }
    switch (method) {
      case "POST":
        Axios.post(url, payload, ReqHeader)
          .then((res) => {
            handelResponse(res);
          })
          .catch((err) => {
            if (typeof onFail === "function") {
              onFail();
            }
            handleClose();
          });
        break;
      case "GET":
        Axios.get(url, ReqHeader)
          .then((res) => {
            handelResponse(res);
          })
          .catch((err) => {
            if (typeof onFail === "function") {
              onFail();
            }
            handleClose();
          });
        break;
      default:
        setOpen(false);
        setloading(false);
        if (typeof onDefault === "function") {
          onDefault();
        }
        break;
    }
  };
  //#endregion functions
  return (
    <Box>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>

        <DialogContent>
          <DialogContentText>{content}</DialogContentText>
          <Box>
            <Grid container spacing={1}>
              {inputs?.map(
                (
                  {
                    hide,
                    autoFocus,
                    name,
                    type,
                    label,
                    options,
                    isSelectMultiple,
                    validators,
                    errorMessages,
                    format,
                    showCopyIcon,
                    ...rest
                  },
                  i
                ) => (
                  <React.Fragment key={i}>
                    {!hide && (
                      <Grid
                        item
                        xs={gridLabel || 3}
                        textAlign={"end"}
                        display={"flex"}
                        alignItems={"flex-start"}
                        flexDirection="column"
                        justifyContent={"flex-start"}
                        mt={1}
                        fontSize="12px"
                        whiteSpace={"nowrap"}
                      >
                        {label}
                      </Grid>
                    )}
                    {!hide && (
                      <Grid item xs={gridInput || 8.5}>
                        <ValidatorForm onError={() => null} onSubmit={() => {}}>
                          <Inputs
                            hide={hide}
                            autoFocus={autoFocus}
                            name={name}
                            value={selectedData[name]}
                            onChange={
                              type === "switch" || type === "boolean"
                                ? handleChecked
                                : type === "autocomplete"
                                ? (event, newValue) =>
                                    handelChangeAutoComplete(
                                      event,
                                      newValue,
                                      name
                                    )
                                : handleChange
                            }
                            type={type}
                            label={label}
                            options={options}
                            isSelectMultiple={isSelectMultiple}
                            validators={validators || []}
                            errorMessages={errorMessages || []}
                            format={format}
                            {...rest}
                          />
                        </ValidatorForm>
                      </Grid>
                    )}
                    {showCopyIcon && (
                      <Grid item xs={0.5}>
                        <Tooltip title="Copy">
                          <ToggleButton
                            selected={
                              name === selectedInput ? OpenTooltip : false
                            }
                            onClick={() => setSelectedInput(name)}
                            onChange={() => {
                              if (name === selectedInput)
                                setOpenTooltip(!OpenTooltip);
                            }}
                            size="small"
                            value={"copy"}
                          >
                            <CopyAll
                              fontSize="small"
                              sx={{ cursor: "pointer" }}
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  selectedData[name]
                                );
                                // setOpenTooltip(true);
                              }}
                            />
                          </ToggleButton>
                        </Tooltip>
                      </Grid>
                    )}
                  </React.Fragment>
                )
              )}
            </Grid>
          </Box>
          {showCustomInfo && <Box>{CustomInfo}</Box>}
        </DialogContent>

        <DialogActions>
          <Button
            color="error"
            variant="contained"
            sx={{ width: "80px" }}
            onClick={handleClose}
          >
            Cancel
          </Button>

          {!hideSaveBtn && (
            <LoadingButton
              loading={loading}
              onClick={handleSave}
              color="primary"
              variant="contained"
              disabled={disabledSave}
              sx={{ color: "primary", width: "80px" }}
            >
              {saveLabel}
            </LoadingButton>
          )}
        </DialogActions>
      </Dialog>
      <Snackbar
        open={OpenAlertMessage}
        autoHideDuration={6000}
        onClose={() => setOpenAlertMessage(false)}
      >
        <Alert
          severity={AlertInfo.severity}
          onClose={() => setOpenAlertMessage(false)}
        >
          {AlertInfo.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}
