export const Urls = {
  Signin: "/Signin",
  Signup: "/Signup",
  ForgotPassword: "/ForgotPassword",
  ResetPassword: "/ResetPassword",
  ConfirmEmail: "/ConfirmEmail",
  Report: "/Report",
  //#region spark urls
  Routers_Dashboard: "/Routers/Dashboard",
  Routers_Devices: "/Routers/Devices",
  Routers_Clients: "/Routers/ClientDevices",
  Routers_Forwarding: "/Routers/Forwarding",
  Routers_RoutersActions: "/Routers/RoutersActions",
  Routers_DedicatedIP: "/Routers/DedicatedIP",
  Routers_AddressList: "/Routers/AddressList",
  Routers_KeepAliveLogs: "/KeepAliveLogs",
  //#endregion spark urls

  //#region Administration urls
  Administration_MappedRouters: "/Administration/MappedRouters",
  Administration_PortGroup: "/Administration/PortGroup",
  Administration_Sim: "/Administration/Sim",
  Administration_SimRack: "/Administration/SimRack",
  Administration_SimGroups: "/Administration/SimGroups",
  Administration_ActivationHistory: "/Administration/ActivationHistory",
  Administration_UpdateHistory: "/Administration/UpdateHistory",
  Administration_ResellerAccounts: "/Administration/ResellerAccounts",
  Administration_Resellers: "/Administration/Resellers",
  Administration_ResellerPortGroup: "/Administration/ResellerPortGroup",
  Administration_Sites: "/Administration/Sites",
  //#region Administration urls

  //#region Mikrotik urls
  Mikrotik_Devices: "/Mikrotik/Devices",
  Mikrotik_Nat: "/Mikrotik/Nat",
  Mikrotik_Rules: "/Mikrotik/Rules",
  Mikrotik_Wireguard_Servers: "/Mikrotik/WireguardServers",
  Mikrotik_Wireguard_Clients: "/Mikrotik/WireguardClients",
  Mikrotik_AddressList: "/Mikrotik/AddressList",

  Dedicated_Server: "/Dedicated/Server",
  Dedicated_Wireguard: "/Dedicated/Wireguard",
  Dedicated_WhiteList: "/Dedicated/WhiteList",

  DigitalOcean_Servers: "/DigitalOcean/Servers",
  Hetzner_Servers: "/Hetzner/Servers",
  //#endregion Mikrotik urls

  //#region Management urls
  Management_Accounts: "/Management/Accounts",
  Management_Roles: "/Management/Roles",
  Management_Tracking: "/Management/Tracking",
  Management_Settings: "/Management/Settings",
  Management_Log: "/Management/Log",
  Management_LogsTree: "/Management/LogsTree",
  //#endregion Management urls
  Notification: "/Notification",
};
