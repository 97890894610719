import React, { useEffect } from "react";
import {
  styled,
  MenuItem,
  FormControl,
  Select,
  Switch,
  Autocomplete,
  TextField,
  TextareaAutosize,
} from "@mui/material";
//import { createFilterOptions } from "@mui/material/Autocomplete";
import { TextValidator } from "react-material-ui-form-validator";
import CreatableSelect from "react-select/creatable";
import PropTypes from "prop-types";

const CssTextField = styled(TextValidator)(() => ({
  width: "100%",
  marginBottom: "8px",
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      border: "1px solid",
      borderColor: "rgba(52, 49, 76, 0.54)",
    },
  },
}));

function Inputs({
  hide,
  autoFocus,
  name,
  value,
  onChange,
  type,
  label,
  multiline,
  disabled,
  options = [],
  isSelectMultiple,
  validators,
  errorMessages,
  format,
  setSelectedData,
  isLoading,
  ...rest
}) {
  useEffect(() => {
    if (type === "select" && options?.length === 1) {
      setSelectedData((prev) => ({
        ...prev,
        [name]: value
          ? value
          : options?.length === 1 && isSelectMultiple
          ? [options[0]?.id]
          : options?.length === 1 && !isSelectMultiple
          ? options[0]?.id
          : isSelectMultiple
          ? []
          : undefined,
      }));
    }
    if (type === "autocomplete" && options?.length === 1) {
      setSelectedData((prev) => ({
        ...prev,
        [name]: value
          ? value
          : options?.length === 1 && isSelectMultiple
          ? [options[0]?.name]
          : options?.length === 1 && !isSelectMultiple
          ? options[0]?.name
          : isSelectMultiple
          ? []
          : undefined,
      }));
    }
  }, [setSelectedData, type, isSelectMultiple, options, name, value]);
  let InputField = (
    <CssTextField
      fullWidth={true}
      autoFocus={autoFocus}
      name={name}
      value={value || ""}
      onChange={onChange}
      id={name}
      type={type}
      validators={validators}
      errorMessages={errorMessages}
      format={format}
      //inputStyle={{ padding: "8.5px 14px" }}
      InputLabelProps={{ shrink: true }}
      size="small"
      multiline={multiline}
      disabled={disabled}
      {...rest}
    />
  );
  if (type === "textarea") {
    InputField = (
      <>
        <TextareaAutosize
          sx={{ width: "500px" }}
          name={name}
          value={value || ""}
          onChange={onChange}
        />
      </>
    );
  }
  if (type === "creatable-select") {
    InputField = (
      <>
        <CreatableSelect
          isClearable
          // ! options [ {value, label} ]
          options={options}
          value={value}
          //value={{ value: "blue", label: "blue" }}
          onChange={(val) =>
            setSelectedData((prev) => ({ ...prev, [name]: val }))
          }
          className="custom-react-select-style"
          isLoading={isLoading}
          styles={{
            control: (provider, state) => ({
              ...provider,
              fontSize: "14px",
            }),
            menu: (provider, state) => ({
              ...provider,
              fontSize: "14px",
              // zIndex: "2203 !important",
            }),
          }}
        />
        {/* <Autocomplete
          fullWidth
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          size="small"
          options={options?.map((option) => option?.name)}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{ ...params.InputProps, type: "search" }}
            />
          )}
          name={name}
          value={value}
          onChange={(event, newValue) => {
            if(typeof newValue === "string") {
              setSelectedData((prev) => ({...prev, [name]: newValue}))
            } else if (newValue && newValue.inputValue) {
              setSelectedData((prev) => ({
                ...prev,
                [name]: newValue.inputValue,
              }));
            } else {
              setSelectedData((prev) => ({ ...prev, [name]: newValue }));
            }
           }}
          multiple={isSelectMultiple}
          {...rest}
        /> */}
      </>
    );
  }
  if (type === "select")
    InputField = (
      <>
        <FormControl fullWidth>
          <Select
            labelId="input-select-label"
            id="input-select"
            size="small"
            autoFocus={autoFocus}
            name={name}
            value={value ? value : isSelectMultiple ? [] : ""}
            //label={label}
            onChange={onChange}
            multiple={isSelectMultiple}
            disabled={disabled}
            {...rest}
          >
            {options?.map((val, i) => (
              <MenuItem key={i} value={val?.id || val?.name}>
                {val?.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </>
    );
  if (type === "autocomplete")
    InputField = (
      <>
        <Autocomplete
          fullWidth
          freeSolo
          disableClearable
          size="small"
          options={options?.map((option) => option?.name)}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{ ...params.InputProps, type: "search" }}
            />
          )}
          name={name}
          value={value ? value : isSelectMultiple ? [] : ""}
          //label={label}
          onChange={onChange}
          multiple={isSelectMultiple}
          {...rest}
        />
      </>
    );
  if (type === "switch" || type === "boolean")
    InputField = (
      <>
        <Switch name={name} checked={value || false} onChange={onChange} />
      </>
    );
  return <>{!hide && InputField}</>;
}

export default Inputs;

Inputs.propTypes = {
  type: PropTypes.oneOf([
    "text",
    "number",
    "password",
    "email",
    "date",
    "select",
    "switch",
    "boolean",
    "autocomplete",
    "creatable-select",
  ]),
};
