export const Api = {
  MyAccount: "/Identity/Account/Me",
  Logout: "/Identity/Account/Logout",
  RegisterClient: "/Identity/Account/RegisterClient",
  RegisterReSellerAccount: "/Identity/Account/RegisterReSellerAccount",
  RefreshToken: "/Identity/Account/RefreshToken",
  Login: "/Identity/Account/Login",
  ForgotPassword: "/Identity/Account/ForgotPassword",
  ResetPassword: "/Identity/Account/ResetPassword",
  ConfirmEmail: "/Identity/Account/ConfirmEmail",
  ChangeEmail: "/Identity/Account/ChangeEmail",
  ConfirmUserEmail: "/Identity/Account/ConfirmUserEmail",
  SendChangeEmailToken: "/Identity/Account/SendChangeEmailToken",
  DeleteAccount: "/Identity/Account/DeleteAccount",
  GetApplicationUserFilter: "/Identity/Account/GetApplicationUserFilter",
  SendEmailConfirmationToken: "/Identity/Account/SendEmailConfirmationToken",
  CreateUser: "/Identity/Administrator/CreateUser",
  CreateResellerAccount: "/Identity/Account/CreateResellerAccount",
  GetResellers: "/Identity/Reseller/GetResellers",
  SaveReseller: "/Identity/Reseller/SaveReseller",
  DeleteReseller: "/Identity/Reseller/DeleteReseller",
  GetResellerAccounts: "/Identity/Reseller/GetResellerAccounts",
  EditResellerAccount: "/Identity/Reseller/EditResellerAccount",
  GetResellersFitler: "/Identity/Reseller/GetResellersFitler",
  AssignPortGroups: "/Identity/Reseller/AssignPortGroups",
  GetResellerPortGroups: "/Identity/Reseller/GetResellerPortGroups",
  //#region routers section
  Routers_GetAPIFiltersInfo: "/Routers/Filter/GetAPIFiltersInfo",
  Ping: "/Routers/Router/Ping",
  GetFirmwareFilter: "/Routers/Router/GetFirmwareFilter",
  // PortForwarding
  GetSimGroups: "/Routers/Sim/GetSimGroups",
  ActivateSims: "/Routers/Sim/ActivateSims",
  ActivateSim: "/Routers/Sim/ActivateSim",
  UpdateSims: "/Routers/Sim/UpdateSims",
  GetSims: "/Routers/Sim/GetSims",
  GetSimStatusFilter: "/Routers/Sim/GetSimStatusFilter",
  GetSimActivationsHistory: "/Routers/Sim/GetSimActivationsHistory",
  GetSimUpdateHistory: "/Routers/Sim/GetSimUpdateHistory",
  GetRoutersActions: "/Routers/Router/GetRoutersActions",
  GetSimRacks: "/Routers/SimRack/GetSimRacks",
  UpdateSimRack: "/Routers/SimRack/UpdateSimRack",
  GetPDUs: "/Routers/SimRack/GetPDUs",
  GetPdusFilter: "/Routers/SimRack/GetPdusFilter",
  GetRouterPing: "/Routers/Dashboard/GetRouterPing",
  AddPortForward: "/Routers/PortForwarding/AddPortForward",
  UpdatePortForward: "/Routers/PortForwarding/UpdatePortForward",
  DeletePortForward: "/Routers/PortForwarding/DeletePortForward",
  GetPortForwards: "/Routers/PortForwarding/GetPortForwards",
  RetryAddPortForward: "/Routers/PortForwarding/RetryAddPortForward",
  GetPortForward: "/Routers/PortForwarding/GetPortForward",
  CheckPort: "/Routers/PortForwarding/CheckPort",
  LinkToAddressList: "/Routers/PortForwarding/LinkToAddressList",
  UnLinkAddressList: "/Routers/PortForwarding/UnLinkAddressList",
  // DedicatedIp
  DedicatedIp_Create: "/Routers/DedicatedIp/Create",
  DedicatedIp_Delete: "/Routers/DedicatedIp/Delete",
  DedicatedIp_GetDedicatedIP: "/Routers/DedicatedIp/GetDedicatedIP",
  DedicatedIp_GetDedicatedIPs: "/Routers/DedicatedIp/GetDedicatedIPs",
  DedicatedIp_ReConfig: "/Routers/DedicatedIp/ReConfig",
  // Hetzner

  GetHetznerServerTypesFilter: "/Cloud/Filter/GetHetznerServerTypesFilter",
  GetDigitalOceanSizeFilter: "/Cloud/Filter/GetDigitalOceanSizeFilter",
  GetDigitalOceanVpcFilter: "/Cloud/Filter/GetDigitalOceanVpcFilter",
  // Router
  ConfigureRouter: "/Routers/Router/ConfigureRouter",
  ChangeCredentials: "/Routers/Router/ChangeCredentials",
  GetRouterFilter: "/Routers/Router/GetRouterFilter",
  GetRouterStatusFilter: "/Routers/Router/GetRouterStatusFilter",
  SaveRouter: "/Routers/Router/SaveRouter",
  simpleSaveRouter: "/Routers/Router/simpleSaveRouter",
  DeleteRouter: "/Routers/Router/DeleteRouter",
  GetRouter: "/Routers/Router/GetRouter",
  GetRouters: "/Routers/Router/GetRouters",
  LinkWithClient: "/Routers/Router/LinkWithClient",
  LinkWithReseller: "/Routers/Router/LinkWithReseller",
  UnlinkClient: "/Routers/Router/UnlinkClient",
  UnlinkReseller: "/Routers/Router/UnlinkReseller",
  GetUserRouters: "/Routers/Router/GetUserRouters",
  RoutersSync: "/Routers/Sync/SyncRouters",
  GetClientFilter: "/Identity/Account/GetClientFilter",
  GetResellerAccountFilter: "/Identity/Account/GetResellerAccountFilter",
  GetMappedRouters: "/Routers/Router/GetMappedRouters",
  CreateRouter: "/Routers/Router/CreateRouter",
  // PortGroup
  GetPortGroup: "/Routers/PortGroup/GetPortGroup",
  GetPortGroupFilter: "/Routers/PortGroup/GetPortGroupFilter",
  // Report
  GetPortForWardReport: "/Routers/Report/GetPortForWardReport",
  GetDedicatedIpReport: "/Routers/Report/GetDedicatedIpReport",
  GetReportFileTypeFilter: "/Routers/Report/GetReportFileTypeFilter",
  GetRouterActionReport: "/Routers/Report/GetRouterActionReport",
  GetSimReport: "/Routers/Report/GetSimReport",
  // IQSimAction
  GetNetSpeedTestResult: "/Routers/IQSimAction/GetNetSpeedTestResult",
  GetNetSpeedResultsHistory: "/Routers/IQSimAction/GetNetSpeedResultsHistory",
  GetNetScanHistory: "/Routers/IQSimAction/GetNetScanHistory",
  GetStartNetScanTest: "/Routers/IQSimAction/GetStartNetScanTest",
  GetStartNetSpeedTest: "/Routers/IQSimAction/GetStartNetSpeedTest",
  ChangePortGroup: "/Routers/IQSimAction/ChangePortGroup",
  DeactivateDevice: "/Routers/IQSimAction/DeactivateDevice",
  ActivateDevice: "/Routers/IQSimAction/ActivateDevice",
  GetStartNetDiag: "/Routers/IQSimAction/GetStartNetDiag",
  GetNetDiagResult: "/Routers/IQSimAction/GetNetDiagResult",
  GetActivateGsmPort: "/Routers/IQSimAction/GetActivateGsmPort",
  GetDeviceInfo: "/Routers/IQSimAction/GetDeviceInfo",
  GetSpeedTestType: "/Routers/IQSimAction/GetSpeedTestType",
  // Site
  GetSiteFitler: "/Routers/Site/GetSiteFitler",
  SaveSite: "/Routers/Site/SaveSite",
  DeleteSite: "/Routers/Site/DeleteSite",
  GetSite: "/Routers/Site/GetSite",
  GetSiteRouters: "/Routers/Site/GetSiteRouters",
  GetSites: "/Routers/Site/GetSites",

  GetRouterMails: "/Routers/Router/GetRouterMails",
  CreateRouterMail: "/Routers/Router/CreateRouterMail",
  UpdateRouterMail: "/Routers/Router/UpdateRouterMail",
  DeleteRouterMail: "/Routers/Router/DeleteRouterMail",
  GetConnectedDevices: "/Routers/Router/GetConnectedDevices",
  RebootRouter: "/Routers/Router/RebootRouter",
  ResetRouter: "/Routers/Router/ResetRouter",

  Mikrotik_AddressList_GetAddressListFilter:
    "/Mikrotik/AddressList/GetAddressListFilter",
  Mikrotik_AddressList_Create: "/Mikrotik/AddressList/Create",
  Mikrotik_AddressList_Update: "/Mikrotik/AddressList/Update",
  Mikrotik_AddressList_Delete: "/Mikrotik/AddressList/Delete",
  Mikrotik_AddressList_GetList: "/Mikrotik/AddressList/GetList",
  Mikrotik_AddressList_GetLists: "/Mikrotik/AddressList/GetLists",

  Mikrotik_AddressListIp_AddressListIp: "/Mikrotik/AddressListIp/AddressListIp",
  Mikrotik_AddressListIp_GetListIp: "/Mikrotik/AddressListIp/GetListIp",
  Mikrotik_AddressListIp_Create: "/Mikrotik/AddressListIp/Create",
  Mikrotik_AddressListIp_Update: "/Mikrotik/AddressListIp/Update",
  Mikrotik_AddressListIp_Delete: "/Mikrotik/AddressListIp/Delete",
  //#endregion routers section

  //#region Mikrotik
  GetMikrotikWireGuardServers: "/Mikrotik/Mikrotik/GetMikrotikWireGuardServers",
  Mikrotik_GetAPIFiltersInfo: "/Mikrotik/Filter/GetAPIFiltersInfo",

  GetMikrotikFilter: "/Mikrotik/Mikrotik/GetMikrotikFilter",
  SaveMikrotik: "/Mikrotik/Mikrotik/SaveMikrotik",
  DeleteMikrotik: "/Mikrotik/Mikrotik/DeleteMikrotik",
  GetMikrotiks: "/Mikrotik/Mikrotik/GetMikrotiks",
  GetMikrotikVersion: "/Mikrotik/Mikrotik/GetMikrotikVersion",
  GetRule: "/Mikrotik/Mikrotik/GetRule",

  GetProtocolFilter: "/Mikrotik/Network/GetTransportProtocolFilter",
  GetApplicationProtocolFilter:
    "/Mikrotik/Network/GetApplicationProtocolFilter",
  GetFirewallNats: "/Mikrotik/Nat/GetFirewallNats",

  GetWireGuardClientFilter:
    "/Mikrotik/WireGuardClients/GetWireGuardClientFilter",
  GetWireGuardClients: "/Mikrotik/WireGuardClients/GetWireGuardClients",
  CreateWireGuardClient: "/Mikrotik/WireGuardClients/CreateWireGuardClient",
  UpdateWireGuardClient: "/Mikrotik/WireGuardClients/UpdateWireGuardClient",
  DeleteWireGuardClient: "/Mikrotik/WireGuardClients/DeleteWireGuardClient",
  GetWireGuardClient: "/Mikrotik/WireGuardClients/GetWireGuardClient",

  GetWireGuardServers: "/Mikrotik/WireGuardServers/GetWireGuardServers",
  GetWireGuardServerFilter:
    "/Mikrotik/WireGuardServers/GetWireGuardServerFilter",
  CreateWireGuardServer: "/Mikrotik/WireGuardServers/CreateWireGuardServer",
  UpdateWireGuardServer: "/Mikrotik/WireGuardServers/UpdateWireGuardServer",
  DeleteWireGuardServer: "/Mikrotik/WireGuardServers/DeleteWireGuardServer",
  GetWireGuardServer: "/Mikrotik/WireGuardServers/GetWireGuardServer",
  GetWireGuardServerPeers: "/Mikrotik/WireGuardServers/GetWireGuardServerPeers",
  GetRules: "/Mikrotik/Mikrotik/GetRules",
  //#endregion Mikrotik

  //#region Management
  Management_GetAPIFiltersInfo: "/Management/Filter/GetAPIFiltersInfo",
  GetTracking: "/Management/ActivityTracking/GetTracking",

  GetUsers: "/Identity/Administrator/GetUsers",
  UpdateUser: "/Identity/Account/UpdateUser",
  UnLock: "/Identity/Administrator/UnLock",
  Lock: "/Identity/Administrator/Lock",
  AddUserToRoles: "/Identity/Administrator/AddUserToRoles",
  ChangePassword: "/Identity/Account/ChangePassword",
  RemoveUserTokens: "/Identity/Administrator/RemoveUserTokens",
  Identity_GetAPIFiltersInfo: "/Identity/Filter/GetAPIFiltersInfo",
  AdminChangePassword: "/Identity/Account/AdminChangePassword",

  GetRolesNames: "/Identity/Administrator/GetRolesNames",
  AddRolePermission: "/Identity/Administrator/AddRolePermission",
  CreateRole: "/Identity/Administrator/CreateRole",
  EditRole: "/Identity/Administrator/EditRole",
  GetPermissions: "/Identity/Administrator/GetPermissions",
  GetRole: "/Identity/Administrator/GetRole",
  RemovePermssionFromRole: "/Identity/Administrator/RemovePermssionFromRole",
  RemoveRole: "/Identity/Administrator/RemoveRole",
  EditeUserAccount: "/Identity/Administrator/EditeUserAccount",
  RemoveUser: "/Identity/Administrator/RemoveUser",
  GetLog: "/Management/Log/GetLog",
  GetLogTypes: "/Management/Log/GetLogTypes",
  GetLogModules: "/Management/Log/GetLogModules",
  GetLogsTree: "/Management/Log/GetLogsTree",
  GetAllSettings: "/Management/Setting/GetAllSettings",
  UpdateSettingConfigurations:
    "/Management/Setting/UpdateSettingConfigurations",
  //#endregion Management

  //#region dashboard
  GetClientCount: "/Identity/DashBoard/GetClientCount",
  GetResellerCount: "/Identity/DashBoard/GetResellerCount",
  GetUserCount: "/Identity/DashBoard/GetUserCount",
  GetRolesCount: "/Identity/DashBoard/GetRolesCount",
  GetUsersinRoleCount: "/Identity/DashBoard/GetUsersinRoleCount",
  GetRoutersCount: "/Routers/Dashboard/GetRoutersCount",
  GetPortForwardCount: "/Routers/Dashboard/GetPortForwardCount",
  GetRouterPortForwardCount: "/Routers/Dashboard/GetRouterPortForwardCount",
  GetRouterStats: "/Routers/Router/GetRouterStats",
  GetServiceUptime: "/Routers/IQSimAction/GetServiceUpTime",

  GetWireGuardClientCount: "/Mikrotik/Dashboard/GetWireGuardClientCount",
  GetMikrotikCount: "/Mikrotik/Dashboard/GetMikrotikCount",
  GetWireGuardServersCount: "/Mikrotik/Dashboard/GetWireGuardServersCount",
  GetWireGuardServersInMikrotikCount:
    "/Mikrotik/Dashboard/GetWireGuardServersInMikrotikCount",
  GetWireGuardServerClientsCount:
    "/Mikrotik/Dashboard/GetWireGuardServerClientsCount",
  GetAvaregeOfClientInServers:
    "/Mikrotik/Dashboard/GetAvaregeOfClientInServers",
  GetNumberOfMikrotikPerCountry:
    "/Mikrotik/Dashboard/GetNumberOfMikrotikPerCountry",
  GetServersPerMikrotik: "/Mikrotik/Dashboard/GetServersPerMikrotik",
  GetClientsPerServer: "/Mikrotik/Dashboard/GetClientsPerServer",
  //#endregion dashboard
  GetPassword: "/Cloud/Server/GetPassword",
  CreateServer: "/Cloud/Server/CreateServer",
  Update: "/Cloud/Server/Update",
  Delete: "/Cloud/Server/Delete",
  Reboot: "/Cloud/Server/Reboot",
  GetServer: "/Cloud/Server/GetServer",
  Rebuild: "/Cloud/Server/Rebuild",
  RebuildWithNewImage: "/Cloud/Server/RebuildWithNewImage",
  PowerOff: "/Cloud/Server/PowerOff",
  PowerOn: "/Cloud/Server/PowerOn",
  TestSSh: "/Cloud/Server/TestSSh",
  GetProviderServers: "/Cloud/Server/GetProviderServers",
  GetServers: "/Cloud/Server/GetServers",
  RetrySetPassword: "/Cloud/Server/RetrySetPassword",
  CreateWireguard: "/Cloud/WireGuard/CreateWireguard",
  DeleteWireguard: "/Cloud/WireGuard/DeleteWireguard",
  GetWireguard: "/Cloud/WireGuard/GetWireguard",
  GetWireguards: "/Cloud/WireGuard/GetWireguards",
  Cloud_GetAPIFiltersInfo: "/Cloud/Filter/GetAPIFiltersInfo",
  GetCloudProviderFilter: "/Cloud/Filter/GetCloudProviderFilter",
  GetLocationsFilter: "/Cloud/Filter/GetLocationsFilter",
  GetImagesFilter: "/Cloud/Filter/GetImagesFilter",
  Cloud_CreateWireguard: "/Cloud/WireGuard/CreateWireguard",
  Cloud_DeleteWireguard: "/Cloud/WireGuard/DeleteWireguard",
  Cloud_GetWireguard: "/Cloud/WireGuard/GetWireguard",
  Cloud_GetWireguardByServerId: "/Cloud/WireGuard/GetWireguardByServerId",
  Cloud_GetWireguards: "/Cloud/WireGuard/GetWireguards",
  Cloud_ReAdd: "/Cloud/WireGuard/ReAdd",
  Cloud_AllowedIP_Create: "/Cloud/AllowedIP/Create",
  Cloud_AllowedIP_Delete: "/Cloud/AllowedIP/Delete",
  Cloud_AllowedIP_Get: "/Cloud/AllowedIP/Get",
  Cloud_AllowedIP_GetServerAllowedIp: "/Cloud/AllowedIP/GetServerAllowedIp",
  Cloud_AllowedIP_GetAllowedIPs: "/Cloud/AllowedIP/GetAllowedIPs",

  GetKeepAliveData: "/Routers/RouterStats/GetKeepAliveData",
  GetKeepAliveChartData: "/Routers/RouterStats/GetKeepAliveChartData",
  SetupKeepAlive: "/Routers/RouterStats/SetupKeepAlive",
  KeepAlive: "/Routers/RouterStats/KeepAlive",
  DeleteKeepAlive: "/Routers/RouterStats/DeleteKeepAlive",
};
