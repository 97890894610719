import { default as version } from "../package.json";
import {
  Alert,
  Snackbar,
  CircularProgress,
  CssBaseline,
  Typography,
} from "@mui/material";
import { useRoutes } from "react-router-dom";
import MatxTheme from "./Components/MatxTheme/MatxTheme";
import { useState, useEffect, useContext } from "react";
import { HubConnectionBuilder, HttpTransportType } from "@microsoft/signalr";
//import { AuthProvider } from "./Contexts/JWTAuthContext";
import { SettingsProvider } from "./Contexts/SettingsContext";
import StateFilterContext from "./Contexts/FilterContext";
import { DataListeningContext } from "./Contexts/DataListeningContext";
import routes from "./Routes";
import Layout1 from "./Components/MatxLayout/Layout1/Layout1";
import { AuthProvider } from "./Contexts/JWTAuthContext";
import ErrorBoundaries from "./ErrorBoundaries";
import CacheBuster from "react-cache-buster";
import "./App.css";

const App = () => {
  const content = useRoutes(routes);
  const isProduction = process.env.NODE_ENV === "production";
  const _DataListeningContext = useContext(DataListeningContext);
  const { setDataListening } = _DataListeningContext;
  const [OpenAlertMessage, setOpenAlertMessage] = useState(false);
  const [AlertInfo, setAlertInfo] = useState({
    severity: "success",
    message: "",
  });
  const token = localStorage.getItem("token");
  useEffect(() => {
    if (token) {
      let connection = new HubConnectionBuilder()
        .withUrl(`wss://${window.gateWayDomain}/Cloud/hub/CloudHub`, {
          skipNegotiation: true,
          transport: HttpTransportType.WebSockets,
          accessTokenFactory: () => localStorage.getItem("token"),
        })
        .withAutomaticReconnect()
        .build();

      connection
        .start()
        .then(() => console.log("Connection Started!"))
        .catch((err) => console.log("errors", err));

      connection.on("PushPasswordUpdate", (data) => {
        console.log("data listening", data);
        setDataListening(data);
        setAlertInfo({
          severity:
            data?.error || `${data?.stateString}`.toLowerCase() === "fail"
              ? "error"
              : "success",
          message: (
            <>
              <Typography>Server: {data?.serverName}</Typography>
              <Typography>Message: {data?.message}</Typography>
            </>
          ),
        });
        setOpenAlertMessage(true);
      });
      connection.on("PushWireGuardUpdate", (data) => {
        console.log("data listening", data);
        setDataListening(data);
        setAlertInfo({
          severity:
            data?.error || `${data?.stateString}`.toLowerCase() === "fail"
              ? "error"
              : "success",
          message: (
            <>
              <Typography>Server: {data?.serverName}</Typography>
              <Typography>Message: {data?.message}</Typography>
            </>
          ),
        });
        setOpenAlertMessage(true);
      });
      connection.on("invokeError", (err) => {
        console.log(err.message);
      });
    }
  }, [token, setDataListening]);
  return (
    <>
      <CacheBuster
        currentVersion={version.version}
        isEnabled={isProduction}
        isVerboseMode={false}
        loadingComponent={<CircularProgress />}
        metaFileDirectory={"."}
      >
        <SettingsProvider>
          <AuthProvider>
            <StateFilterContext>
              <MatxTheme>
                <CssBaseline />
                {token ? (
                  <Layout1>
                    <ErrorBoundaries>{content} </ErrorBoundaries>
                  </Layout1>
                ) : (
                  <ErrorBoundaries>{content}</ErrorBoundaries>
                )}
                <Snackbar
                  open={OpenAlertMessage}
                  autoHideDuration={10000}
                  onClose={() => setOpenAlertMessage(false)}
                >
                  <Alert
                    severity={AlertInfo.severity}
                    onClose={() => setOpenAlertMessage(false)}
                  >
                    {AlertInfo.message}
                  </Alert>
                </Snackbar>
              </MatxTheme>
            </StateFilterContext>
          </AuthProvider>
        </SettingsProvider>
      </CacheBuster>
    </>
  );
};

export default App;
