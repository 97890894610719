/* eslint-disable array-callback-return */
import { differenceInSeconds } from "date-fns";

export const convertHexToRGB = (hex) => {
  // check if it's a rgba
  if (hex.match("rgba")) {
    let triplet = hex.slice(5).split(",").slice(0, -1).join(",");
    return triplet;
  }

  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");

    return [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",");
  }
};

export function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    clearTimeout(timeout);
    timeout = setTimeout(function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    }, wait);
    if (immediate && !timeout) func.apply(context, args);
  };
}

export function isMobile() {
  if (window) return window.matchMedia(`(max-width: 767px)`).matches;

  return false;
}

export function isMdScreen() {
  if (window) return window.matchMedia(`(max-width: 1199px)`).matches;

  return false;
}

function currentYPosition(elm) {
  if (!window && !elm) {
    return;
  }
  if (elm) return elm.scrollTop;
  // Firefox, Chrome, Opera, Safari
  if (window.pageYOffset) return window.pageYOffset;
  // Internet Explorer 6 - standards mode
  if (document.documentElement && document.documentElement.scrollTop)
    return document.documentElement.scrollTop;
  // Internet Explorer 6, 7 and 8
  if (document.body.scrollTop) return document.body.scrollTop;
  return 0;
}

function elmYPosition(elm) {
  var y = elm.offsetTop;
  var node = elm;
  while (node.offsetParent && node.offsetParent !== document.body) {
    node = node.offsetParent;
    y += node.offsetTop;
  }
  return y;
}

export function scrollTo(scrollableElement, elmID) {
  var elm = document.getElementById(elmID);

  if (!elmID || !elm) {
    return;
  }

  var startY = currentYPosition(scrollableElement);
  var stopY = elmYPosition(elm);

  var distance = stopY > startY ? stopY - startY : startY - stopY;
  if (distance < 100) {
    scrollTo(0, stopY);
    return;
  }
  var speed = Math.round(distance / 50);
  if (speed >= 20) speed = 20;
  var step = Math.round(distance / 25);
  var leapY = stopY > startY ? startY + step : startY - step;
  var timer = 0;
  if (stopY > startY) {
    for (var i = startY; i < stopY; i += step) {
      setTimeout(
        (function (leapY) {
          return () => {
            scrollableElement.scrollTo(0, leapY);
          };
        })(leapY),
        timer * speed
      );
      leapY += step;
      if (leapY > stopY) leapY = stopY;
      timer++;
    }
    return;
  }
  for (let i = startY; i > stopY; i -= step) {
    setTimeout(
      (function (leapY) {
        return () => {
          scrollableElement.scrollTo(0, leapY);
        };
      })(leapY),
      timer * speed
    );
    leapY -= step;
    if (leapY < stopY) leapY = stopY;
    timer++;
  }
  return false;
}

export function getTimeDifference(date) {
  let difference = differenceInSeconds(new Date(), date);

  if (difference < 60) return `${Math.floor(difference)} sec`;
  else if (difference < 3600) return `${Math.floor(difference / 60)} min`;
  else if (difference < 86400) return `${Math.floor(difference / 3660)} h`;
  else if (difference < 86400 * 30)
    return `${Math.floor(difference / 86400)} d`;
  else if (difference < 86400 * 30 * 12)
    return `${Math.floor(difference / 86400 / 30)} mon`;
  else return `${(difference / 86400 / 30 / 12).toFixed(1)} y`;
}

export const ErrorsResKeys = [
  "Error",
  "Forbidden",
  "Exception",
  "NotFound",
  "DuplicateRecord",
  "UnAuthorized",
  "BadRequest",
  "NotSupported",
  "AccessDenined",
  "NotAllowed",
];
export function ExtractErrorMessage(data) {
  let messages = [],
    severity = "",
    summary = "",
    isReqSuccess = false;

  if (data?.Success?.succeeded === true || data?.Success) {
    messages = ["Request Succeeded"];
    severity = "success";
    summary = "Success";
    isReqSuccess = true;
  }

  ErrorsResKeys?.forEach((val) => {
    if (data[val] === "Forbidden") {
      localStorage.clear();
      window.location.assign("/Login");
    }
    if (data[val]) {
      if (Array.isArray(data[val])) {
        messages.push(`${val}: ${data[val]}`);
      } else {
        data[val].forEach((element) => {
          messages.push(`${element.key}: ${element.message}`);
        });
      }
      severity = "error";
      summary = "Error";
      isReqSuccess = false;
    }
  });
  //console.log(data);
  if (data?.errors) {
    Object.keys(data?.errors).map((key) => {
      messages.push(`${key}: ${data?.errors[key]}`);
    });
    //messages = [data?.title];
    severity = "error";
    summary = [data?.title];
    isReqSuccess = false;
  }
  if (data?.Success?.succeeded === false || data?.Success === false || !data) {
    messages = ["Request Failed"];
    severity = "error";
    summary = "Error";
    isReqSuccess = false;
  }
  // else if (data) {
  //   messages = ["Request Succeeded"];
  //   severity = "success";
  //   summary = "Success";
  //   isReqSuccess = true;
  // }

  return {
    detail: messages,
    severity,
    summary,
    isReqSuccess,
  };
}
export function filterResult(filterList, data, date) {
  // console.log(filterList);
  // console.log("filterResult data", data);
  //   console.log(date);
  let result = {};
  Object.keys(data).map((key) => {
    let type = filterList
      ?.find((ele) => ele?.name === key)
      ?.filterType?.toLowerCase();
    //console.log("type", type);

    switch (type) {
      case "dropdown":
        result = {
          ...result,
          [key]: data[key]
            ? data[key]?.length > 0
              ? data[key][0]?.id
              : undefined
            : undefined,
        };
        break;
      case "number":
      case "text":
        let val = undefined;
        if (Array.isArray(data[key])) {
          if (data[key].length > 0) {
            val = data[key][0].id;
          }
        }
        result = {
          ...result,
          [key]: val,
        };
        break;
      case "multiselect":
        result = {
          ...result,
          [key]: data[key] ? data[key] : undefined,
        };
        break;
      case "arrayoftext":
      case "arrayofnumber":
        result = {
          ...result,
          [key]: data[key] ? data[key].map((ele) => ele.id) : undefined,
        };
        break;
      case "daterange":
      case "datepicker":
        result = {
          ...result,
          fromTo:
            date?.dateFrom === undefined && date?.dateTo === undefined
              ? undefined
              : {
                  start:
                    date?.dateFrom?.length > 0 ? date?.dateFrom : undefined,
                  end: date?.dateTo?.length > 0 ? date?.dateTo : undefined,
                },
        };
        break;
      case "boolean":
        result = {
          ...result,
          [key]: data[key],
        };
        break;
      case "time":
        result = {
          ...result,
          startHourFrom: {
            hour: data?.StartHourFrom?.hour ? data.StartHourFrom.hour : 0,
            minute: data?.StartHourFrom?.minute ? data.StartHourFrom.minute : 0,
          },
          startHourTo: {
            hour: data?.StartHourTo?.hour ? data.StartHourTo.hour : 23,
            minute: data?.StartHourTo?.minute ? data.StartHourTo.minute : 59,
          },
        };
        break;
      default:
        break;
    }
  });
  if (date) {
    result = {
      ...result,
      fromTo:
        date?.dateFrom === undefined && date?.dateTo === undefined
          ? undefined
          : {
              start: date?.dateFrom?.length > 0 ? date?.dateFrom : undefined,
              end: date?.dateTo?.length > 0 ? date?.dateTo : undefined,
            },
    };
  }
  //console.log("result", result);
  return result;
}
export function findIdByName(list, name) {
  let item = list?.find(
    (ele) => `${ele?.name}`.toLowerCase() === `${name}`.toLowerCase()
  );
  return item?.id;
}
export function handelGlobalSearchLocaly(data, searchQuery) {
  if (searchQuery.length === 0) return data;
  else
    return data?.filter((item) => {
      return Object.keys(item).some((key) =>
        item[key].toString().toLowerCase().includes(searchQuery.toLowerCase())
      );
    });
}
export function getRandom() {
  return Math.floor(Math.pow(10, 9) + Math.random() * 9 * Math.pow(10, 9));
}
export function generateFile(type, data) {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement("a");
  link.href = url;
  if (type === "pdf") link.setAttribute("download", `${getRandom()}.pdf`);
  else if (type === "xlsx")
    link.setAttribute("download", `${getRandom()}.xlsx`);
  else if (type === "csv") link.setAttribute("download", `${getRandom()}.csv`);
  else link.setAttribute("download", `${getRandom}.pdf`);

  document.body.appendChild(link);
  link.click();
}
export function formateKBytes(numberInKB) {
  let units = ["KB", "MB", "GB", "TB"];
  let bytes = parseInt(numberInKB || 0);
  let i;
  for (i = 0; bytes >= 1024 && i < 3; i++) {
    bytes /= 1024;
  }
  return bytes.toFixed(1) + units[i];
}
