import React, { useState } from "react";
//import { addDays } from 'date-fns';
export const StateFilterContext = React.createContext();
const StateFilterProvider = (props) => {
  const { children } = props;
  const [dateFilterContext, setDateFilterContext] = useState({});
  const [HelperDate, setHelperDate] = useState({
    dateFrom: undefined,
    dateTo: undefined,
  });
  const [dataFilterContext, setDataFilterContext] = useState([]);
  function resetFilter() {
    setDateFilterContext({
      dateFrom: undefined,
      dateTo: undefined,
    });
    setHelperDate({
      dateFrom: undefined,
      dateTo: undefined,
    });
    setDataFilterContext({});
  }
  return (
    <StateFilterContext.Provider
      value={{
        HelperDate: HelperDate,
        setHelperDate: setHelperDate,
        dateFilterContext: dateFilterContext,
        setDateFilterContext: setDateFilterContext,
        dataFilterContext: dataFilterContext,
        setDataFilterContext: setDataFilterContext,
        resetFilter,
      }}
    >
      {children}
    </StateFilterContext.Provider>
  );
};
export default StateFilterProvider;
