/* eslint-disable react/destructuring-assignment */
import { Component } from "react";

class ErrorBoundaries extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }
  // eslint-disable-next-line react/sort-comp
  componentDidCatch(error, info) {
    console.log(error);
  }
  render() {
    if (this.state.hasError) {
      return (
        <h3 style={{ color: "red", marginLeft: "5px" }}>
          Something went wrong!
        </h3>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundaries;
