import { Api } from "./Api";
import axios from "axios";
import { Urls } from "./URLs";
import {
  token,
  refreshToken,
  updatedAccessTokenExpirtion,
  refreshExpiration,
  currentDate,
} from "./Utils/constant";

let _token = token;
let countRefresh = 0;
let configs = [];
let _accessTokenExpirtion = new Date(
  localStorage.getItem("accessTokenExpirtion")
);
//let reSendRequestAfterRefreshToken = false;
function redirectToLogin() {
  localStorage.clear();
  window.location.href = "/";
}
const handleSendResreshToken = async (config) => {
  configs.push(config);

  if (currentDate > new Date(refreshExpiration)) {
    redirectToLogin();
  } else {
    if (countRefresh <= 1) {
      try {
        const res = await axios.post(window.baseURL + Api.RefreshToken, {
          accessToken: token,
          refreshToken: refreshToken,
        });
        if (res) {
          if (res?.data?.Forbidden) {
            redirectToLogin();
          }
          // reSendRequestAfterRefreshToken = true;
          let data = res?.data?.Success;
          let token = data?.accessToken;
          let refreshToken = data?.refreshToken;
          let accessTokenExpirtion = data?.accessTokenExpirtion;
          let refreshExpiration = data?.refreshExpiration;
          _accessTokenExpirtion = new Date(data?.accessTokenExpirtion);
          _token = token;
          localStorage.setItem("token", token);
          localStorage.setItem("refreshToken", refreshToken);
          localStorage.setItem("accessTokenExpirtion", accessTokenExpirtion);
          localStorage.setItem("refreshExpiration", refreshExpiration);
          instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
          config.headers.Authorization = `Bearer ${token}`;
          //return handelSendOtherConfig(configs, token);
          return config;
        }
      } catch (error) {
        redirectToLogin();
      }
      // axios
      //   .post(window.baseURL + Api.RefreshToken, {
      //     accessToken: token,
      //     refreshToken: refreshToken,
      //   })
      //   .then((res) => {
      //     if (res?.data?.Forbidden) {
      //       redirectToLogin();
      //     }
      //     reSendRequestAfterRefreshToken = true;
      //     let data = res?.data?.Success;
      //     let token = data?.accessToken;
      //     let refreshToken = data?.refreshToken;
      //     let accessTokenExpirtion = data?.accessTokenExpirtion;
      //     let refreshExpiration = data?.refreshExpiration;
      //     localStorage.setItem("token", token);
      //     localStorage.setItem("refreshToken", refreshToken);
      //     localStorage.setItem("accessTokenExpirtion", accessTokenExpirtion);
      //     localStorage.setItem("refreshExpiration", refreshExpiration);
      //     // let idx = 0;
      //     // async function resendConfig() {
      //     //   await configs.forEach((config, i) => {
      //     //     idx = i;
      //     //     return instance(config);
      //     //   });
      //     // }
      //     // resendConfig();
      //     // if (idx + 1 === configs.length) {
      //     //   reSendRequestAfterRefreshToken = false;
      //     // }
      //     instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      //     config.headers.Authorization = `Bearer ${token}`;
      //     isRefreshing = true;
      //     // return instance(config);
      //   })
      //   .catch((err) => {
      //     isRefreshing = false;
      //     redirectToLogin();
      //     //return Promise.reject(err);
      //   });
    }
  }
};
// function handelSendOtherConfig(configs, token) {
//   return configs.forEach((config, i) => {
//     console.log("config", config);
//     config.headers.Authorization = `Bearer ${token}`;
//     instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
//     return instance(config);
//   });
// }
const instance = axios.create({
  baseURL: window.baseURL, // process.env.REACT_APP_BASE_URL,
});
function checkIsInvalidAccessToken(accessTokenExpirtion) {
  return updatedAccessTokenExpirtion(accessTokenExpirtion) <= new Date();
}
const configureRequest = (config) => {
  //console.log(config.url);
  config.headers = {
    Authorization: `Bearer ${token}`,
  };
  let config_url = `${config.url}`.split("?")[0];
  let isApisDontNeedToken =
    config_url === Api.Login ||
    config_url === Api.RegisterClient ||
    config_url === Api.ResetPassword ||
    config_url === Api.ForgotPassword ||
    config_url === Api.ConfirmEmail ||
    config_url === Api.SendEmailConfirmationToken;
  if (
    checkIsInvalidAccessToken(_accessTokenExpirtion) &&
    !isApisDontNeedToken
    // && !reSendRequestAfterRefreshToken
  ) {
    //console.log("Invalid Access Token");
    countRefresh = countRefresh + 1;
    return handleSendResreshToken(config);
  } else {
    //console.log("Valid Token");
    configs = [];
    countRefresh = 0;
    config.headers.Authorization = `Bearer ${_token}`;
    instance.defaults.headers.common["Authorization"] = `Bearer ${_token}`;
    return config;
  }
};
const configureResponseError = async ({ config, response }) => {
  // console.log("config", config);
  // console.log(response);
  localStorage.setItem("status", response?.status);
  switch (response?.status) {
    case 401:
      redirectToLogin();
      break;
    case 403:
      window.location.href = Urls.ConfirmEmail;
      break;
    // case 500:
    //   setTimeout(() => {
    //     instance(config);
    //   }, 3000);
    //   break;
    default:
      break;
  }
  return response;
};
instance.interceptors.request.use(configureRequest, (err) => {
  Promise.reject(err);
});
instance.interceptors.response.use((res) => {
  //console.log("res.status", res.status);
  localStorage.setItem("status", res.status);
  return res;
}, configureResponseError);

export default instance;
