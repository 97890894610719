import { lazy } from "react";
import AuthGuard from "./auth/AuthGuard";
//import { authRoles } from "./auth/authRoles";
import Loadable from "./Components/Loadable";
import MatxLayout from "./Components/MatxLayout/MatxLayout";
import { Urls } from "./URLs";
import { isAdmin, isReseller, isClient } from "./Utils/constant";
import NotAuthorize from "./Pages/NotAuthorize";
//import materialRoutes from "./views/material-kit/MaterialRoutes";

// session pages
const NotFound = Loadable(lazy(() => import("./Pages/NotFound")));
const JwtLogin = Loadable(lazy(() => import("./Pages/Auth/JwtLogin")));
const JwtRegister = Loadable(lazy(() => import("./Pages/Auth/JwtRegister")));
const ForgotPassword = Loadable(
  lazy(() => import("./Pages/Auth/ForgotPassword"))
);
const ResetPassword = Loadable(
  lazy(() => import("./Pages/Auth/ResetPassword"))
);
const ConfirmEmail = Loadable(lazy(() => import("./Pages/Auth/ConfirmEmail")));
const Dashboard = Loadable(lazy(() => import("./Pages/Dashboard")));

const RoutersDashboard = Loadable(
  lazy(() => import("./Pages/Spark/Dashboard"))
);
const RoutersDevices = Loadable(lazy(() => import("./Pages/Spark/Devices")));
const KeepAliveLogs = Loadable(
  lazy(() => import("./Pages/Spark/Devices/KeepAliveLogs"))
);
const RoutersClients = Loadable(lazy(() => import("./Pages/Spark/Clients")));
const PortForwards = Loadable(lazy(() => import("./Pages/Spark/PortForwards")));
const RoutersActions = Loadable(
  lazy(() => import("./Pages/Administration/RoutersActions"))
);
const DedicatedIP = Loadable(lazy(() => import("./Pages/Spark/DedicatedIP")));
const SparkAddressList = Loadable(
  lazy(() => import("./Pages/Spark/AddressList"))
);
const PortGroup = Loadable(
  lazy(() => import("./Pages/Administration/PortGroup"))
);
const MappedRouters = Loadable(
  lazy(() => import("./Pages/Administration/MappedRouters"))
);
const Sites = Loadable(lazy(() => import("./Pages/Administration/Sites")));
const Sim = Loadable(lazy(() => import("./Pages/Administration/Sim")));
const SimRack = Loadable(lazy(() => import("./Pages/Administration/SimRack")));
const SimGroups = Loadable(
  lazy(() => import("./Pages/Administration/SimGroups"))
);
const ActivationHistory = Loadable(
  lazy(() => import("./Pages/Administration/ActivationHistory"))
);
const UpdateHistory = Loadable(
  lazy(() => import("./Pages/Administration/UpdateHistory"))
);
const ResellerAccounts = Loadable(
  lazy(() => import("./Pages/Administration/ResellerAccounts"))
);
const Resellers = Loadable(
  lazy(() => import("./Pages/Administration/Resellers"))
);
const ResellerPortGroup = Loadable(
  lazy(() => import("./Pages/Administration/Resellers/ResellerPortGroup"))
);
const MikrotikDevices = Loadable(
  lazy(() => import("./Pages/Cloud/Mikrotik/Devices"))
);
const Nat = Loadable(lazy(() => import("./Pages/Cloud/Mikrotik/Nat")));
const Rules = Loadable(lazy(() => import("./Pages/Cloud/Mikrotik/Rules")));
const WireguardClients = Loadable(
  lazy(() => import("./Pages/Cloud/Mikrotik/WireguardClients"))
);
const WireguardServers = Loadable(
  lazy(() => import("./Pages/Cloud/Mikrotik/WireguardServers"))
);
const MikrotikAddressList = Loadable(
  lazy(() => import("./Pages/Cloud/Mikrotik/AddressList"))
);
const DedicatedWhiteList = Loadable(
  lazy(() => import("./Pages/Cloud/Dedicated/WhiteList"))
);
const DedicatedServer = Loadable(
  lazy(() => import("./Pages/Cloud/Dedicated/Server"))
);
const DedicatedWireguard = Loadable(
  lazy(() => import("./Pages/Cloud/Dedicated/WireguardServers"))
);
const HetznerServers = Loadable(
  lazy(() => import("./Pages/Cloud/Hetzner/Servers"))
);
const DigitalOceanServers = Loadable(
  lazy(() => import("./Pages/Cloud/DigitalOcean/Servers"))
);
const Accounts = Loadable(lazy(() => import("./Pages/Management/Accounts")));
const Roles = Loadable(lazy(() => import("./Pages/Management/Roles")));
const Tracking = Loadable(lazy(() => import("./Pages/Management/Tracking")));
const Settings = Loadable(lazy(() => import("./Pages/Management/Settings")));
const LogsTree = Loadable(lazy(() => import("./Pages/Management/LogsTree")));
const Log = Loadable(lazy(() => import("./Pages/Management/LogsTree/Log")));
const Notification = Loadable(
  lazy(() => import("./Pages/Management/Notification"))
);
const Report = Loadable(lazy(() => import("./Pages/Report")));

let token = localStorage.getItem("token");
const routes = token
  ? [
      {
        element: (
          <AuthGuard>
            <MatxLayout />
          </AuthGuard>
        ),
        children: [
          //...materialRoutes,
          // dashboard route
          {
            path: "/dashboard",
            element: <>test</>,
          },
          // e-chart rooute
          //   {
          //     path: "/charts/echarts",
          //     element: <AppEchart />,
          //     auth: authRoles.editor,
          //   },
        ],
      },
      // routers pages route
      {
        path: Urls.Routers_Dashboard,
        element: <RoutersDashboard />,
      },
      { path: Urls.Routers_Devices, element: <RoutersDevices /> },
      { path: Urls.Routers_KeepAliveLogs, element: <KeepAliveLogs /> },
      { path: Urls.Routers_Clients, element: <RoutersClients /> },
      { path: Urls.Routers_Forwarding, element: <PortForwards /> },
      { path: Urls.Routers_RoutersActions, element: <RoutersActions /> },
      { path: Urls.Routers_DedicatedIP, element: <DedicatedIP /> },
      { path: Urls.Routers_AddressList, element: <SparkAddressList /> },
      {
        path: Urls.Administration_PortGroup,
        element: !isAdmin ? <NotAuthorize /> : <PortGroup />,
      },
      // Mikrotik
      {
        path: Urls.Mikrotik_AddressList,
        element: !isAdmin ? <NotAuthorize /> : <MikrotikAddressList />,
      },
      {
        path: Urls.Mikrotik_Devices,
        element: !isAdmin ? <NotAuthorize /> : <MikrotikDevices />,
      },
      {
        path: Urls.Mikrotik_Nat,
        element: !isAdmin ? <NotAuthorize /> : <Nat />,
      },
      {
        path: Urls.Mikrotik_Rules,
        element: !isAdmin ? <NotAuthorize /> : <Rules />,
      },
      {
        path: Urls.Mikrotik_Wireguard_Clients,
        element: !isAdmin ? <NotAuthorize /> : <WireguardClients />,
      },
      {
        path: Urls.Mikrotik_Wireguard_Servers,
        element: !isAdmin ? <NotAuthorize /> : <WireguardServers />,
      },
      {
        path: Urls.Dedicated_WhiteList,
        element: !isAdmin ? <NotAuthorize /> : <DedicatedWhiteList />,
      },
      {
        path: Urls.Dedicated_Server,
        element: !isAdmin ? <NotAuthorize /> : <DedicatedServer />,
      },
      {
        path: Urls.Dedicated_Wireguard,
        element: !isAdmin ? <NotAuthorize /> : <DedicatedWireguard />,
      },
      {
        path: Urls.Hetzner_Servers,
        element: !isAdmin ? <NotAuthorize /> : <HetznerServers />,
      },
      {
        path: Urls.DigitalOcean_Servers,
        element: !isAdmin ? <NotAuthorize /> : <DigitalOceanServers />,
      },
      // Management
      {
        path: Urls.Management_Accounts,
        element: !isAdmin ? <NotAuthorize /> : <Accounts />,
      },
      {
        path: Urls.Management_Roles,
        element: !isAdmin ? <NotAuthorize /> : <Roles />,
      },
      {
        path: Urls.Management_Tracking,
        element: !isAdmin ? <NotAuthorize /> : <Tracking />,
      },
      {
        path: Urls.Management_Settings,
        element: !isAdmin ? <NotAuthorize /> : <Settings />,
      },
      {
        path: Urls.Administration_MappedRouters,
        element: !isAdmin ? <NotAuthorize /> : <MappedRouters />,
      },
      {
        path: Urls.Administration_Sites,
        element: isReseller ? <NotAuthorize /> : <Sites />,
      },
      {
        path: Urls.Administration_Sim,
        element: !isAdmin ? <NotAuthorize /> : <Sim />,
      },
      {
        path: Urls.Administration_ResellerAccounts,
        element: !isAdmin ? <NotAuthorize /> : <ResellerAccounts />,
      },
      {
        path: Urls.Administration_Resellers,
        element: !isAdmin ? <NotAuthorize /> : <Resellers />,
      },
      {
        path: Urls.Administration_ResellerPortGroup,
        element: !isAdmin ? <NotAuthorize /> : <ResellerPortGroup />,
      },
      {
        path: Urls.Administration_SimRack,
        element: !isAdmin ? <NotAuthorize /> : <SimRack />,
      },
      {
        path: Urls.Administration_SimGroups,
        element: !isAdmin ? <NotAuthorize /> : <SimGroups />,
      },
      {
        path: Urls.Administration_ActivationHistory,
        element: !isAdmin ? <NotAuthorize /> : <ActivationHistory />,
      },
      {
        path: Urls.Administration_UpdateHistory,
        element: !isAdmin ? <NotAuthorize /> : <UpdateHistory />,
      },
      {
        path: Urls.Management_LogsTree,
        element: !isAdmin ? <NotAuthorize /> : <LogsTree />,
      },
      {
        path: Urls.Management_Log,
        element: !isAdmin ? <NotAuthorize /> : <Log />,
      },
      {
        path: Urls.Report,
        // element: !isAdmin ? <NotAuthorize /> : <Report />,
        element: <Report />,
      },

      { path: Urls.ConfirmEmail, element: <ConfirmEmail /> },
      { path: Urls.Notification, element: <Notification /> },

      { path: "/", element: isClient ? <RoutersDashboard /> : <Dashboard /> },
      { path: "*", element: <NotFound /> },
    ]
  : [
      { path: Urls.Signup, element: <JwtRegister /> },
      { path: Urls.ForgotPassword, element: <ForgotPassword /> },
      { path: Urls.ResetPassword, element: <ResetPassword /> },
      { path: Urls.ConfirmEmail, element: <ConfirmEmail /> },
      { path: "/*", element: <JwtLogin /> },
    ];

export default routes;
