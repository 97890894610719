import { Box, Typography, styled } from "@mui/material";

const FlexBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
}));

const JustifyBox = styled(FlexBox)(() => ({
  width: "100%",
  flexDirection: "column",
  justifyContent: "center",
}));

function NotAuthorize() {
  return (
    <JustifyBox>
      <Typography color={"error"} variant="h5" mt={10}>
        Not Authorized
      </Typography>
    </JustifyBox>
  );
}

export default NotAuthorize;
