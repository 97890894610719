import React, { useState } from "react";
//import { addDays } from 'date-fns';
export const DataListeningContext = React.createContext();
const DataListeningProvider = (props) => {
  const { children } = props;
  const [DataListening, setDataListening] = useState({});
  return (
    <DataListeningContext.Provider
      value={{
        DataListening,
        setDataListening,
      }}
    >
      {children}
    </DataListeningContext.Provider>
  );
};
export default DataListeningProvider;
