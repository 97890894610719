import DashboardIcon from "@mui/icons-material/Dashboard";
import SettingsIcon from "@mui/icons-material/Settings";
import RouterIcon from "@mui/icons-material/Router";
import { Urls } from "./URLs";
import {
  AdminPanelSettings,
  Cloud,
  CropSquare,
  Wysiwyg,
} from "@mui/icons-material";
import { isAdmin, isReseller, isClient } from "./Utils/constant";

export const navigations = [
  {
    name: "Dashboard",
    path: isClient ? Urls.Routers_Dashboard : "/",
    hide: isClient,
    icon: <DashboardIcon fontSize="small" sx={{ marginLeft: "15px" }} />,
  },
  { label: "PAGES", type: "label" },
  {
    name: "Spark+",
    icon: <RouterIcon fontSize="small" />,
    children: [
      {
        name: "Dasboard",
        iconText: "SI",
        path: Urls.Routers_Dashboard,
        hide: true,
      },
      { name: "Routers", iconText: "SI", path: Urls.Routers_Devices },
      { name: "Client Devices", iconText: "SI", path: Urls.Routers_Clients },
      {
        name: "Port Forwarding",
        iconText: "SI",
        path: Urls.Routers_Forwarding,
      },
      {
        name: "DedicatedIP",
        iconText: "SI",
        path: Urls.Routers_DedicatedIP,
      },
      {
        name: "AddressList",
        iconText: "SI",
        path: Urls.Routers_AddressList,
      },
    ],
  },
  {
    name: "Cloud",
    icon: <Cloud fontSize="small" />,
    hide: !isAdmin,
    children: [
      {
        name: "Mikrotik",
        iconText: "SI",
        //icon: <CropSquare sx={{ fontSize: "10px" }} />,
        children: [
          {
            name: "Devices",
            iconText: "SI",
            path: Urls.Mikrotik_Devices,
            hide: !isAdmin,
          },
          {
            name: "Nat",
            iconText: "SI",
            path: Urls.Mikrotik_Nat,
            hide: !isAdmin,
          },
          {
            name: "AddressList",
            iconText: "SI",
            path: Urls.Mikrotik_AddressList,
            hide: !isAdmin,
          },
          {
            name: "Rules",
            iconText: "SU",
            path: Urls.Mikrotik_Rules,
            hide: !isAdmin,
          },
          {
            name: "Wireguard Servers",
            iconText: "SI",
            path: Urls.Mikrotik_Wireguard_Servers,
            hide: !isAdmin,
          },
          {
            name: "Wireguard Clients",
            iconText: "SI",
            path: Urls.Mikrotik_Wireguard_Clients,
            hide: !isAdmin,
          },
        ],
      },
      {
        name: "Dedicated",
        iconText: "SI",
        //icon: <CropSquare sx={{ fontSize: "10px" }} />,
        children: [
          {
            name: "Server",
            iconText: "SI",
            path: Urls.Dedicated_Server,
            hide: !isAdmin,
          },
          {
            name: "Wireguard",
            iconText: "SI",
            path: Urls.Dedicated_Wireguard,
            hide: !isAdmin,
          },
          {
            name: "WhiteList",
            iconText: "SI",
            path: Urls.Dedicated_WhiteList,
            hide: !isAdmin,
          },
        ],
      },
      {
        name: "Hetzner",
        iconText: "SI",
        children: [
          {
            name: "Servers",
            iconText: "SU",
            path: Urls.Hetzner_Servers,
            hide: !isAdmin,
          },
          // {
          //   name: "Projects",
          //   iconText: "SU",
          //   path: Urls.Hetzner_Projects,
          //   hide: !isAdmin,
          // },
        ],
      },
      {
        name: "Digital Ocean",
        iconText: "SI",
        children: [
          {
            name: "Servers",
            iconText: "SU",
            path: Urls.DigitalOcean_Servers,
            hide: !isAdmin,
          },
        ],
      },
    ],
  },
  {
    name: "Administration",
    icon: <AdminPanelSettings fontSize="small" />,
    children: [
      {
        name: "Mapped Routers",
        iconText: "SU",
        path: Urls.Administration_MappedRouters,
        hide: !isAdmin,
      },
      {
        name: "Port Group",
        iconText: "SI",
        path: Urls.Administration_PortGroup,
        hide: !isAdmin,
      },
      {
        name: "Reseller Accounts",
        iconText: "SI",
        path: Urls.Administration_ResellerAccounts,
        hide: !isAdmin,
      },
      {
        name: "Resellers",
        iconText: "SI",
        path: Urls.Administration_Resellers,
        hide: !isAdmin,
      },
      {
        name: "Sites",
        iconText: "SU",
        path: Urls.Administration_Sites,
        hide: isReseller,
      },
      {
        name: "Routers Actions",
        iconText: "SI",
        path: Urls.Routers_RoutersActions,
      },
      {
        name: "Manage SIMs",
        iconText: "SU",
        hide: !isAdmin,
        children: [
          {
            name: "Sim",
            iconText: "SU",
            path: Urls.Administration_Sim,
            hide: !isAdmin,
          },
          {
            name: "Sim Rack",
            iconText: "SU",
            path: Urls.Administration_SimRack,
            hide: !isAdmin,
          },
          {
            name: "Sim Groups",
            iconText: "SU",
            path: Urls.Administration_SimGroups,
            hide: !isAdmin,
          },
          {
            name: "Activation History",
            iconText: "SU",
            path: Urls.Administration_ActivationHistory,
            hide: !isAdmin,
          },
          {
            name: "Update History",
            iconText: "SU",
            path: Urls.Administration_UpdateHistory,
            hide: !isAdmin,
          },
        ],
      },
    ],
  },
  {
    name: "Report",
    path: Urls.Report,
    //hide: !isAdmin,
    icon: <Wysiwyg fontSize="small" sx={{ marginLeft: "15px" }} />,
  },
  {
    name: "Management",
    icon: <SettingsIcon fontSize="small" />,
    children: [
      {
        name: "Accounts",
        iconText: "SI",
        path: Urls.Management_Accounts,
        hide: !isAdmin,
      },
      {
        name: "Tracking",
        iconText: "SI",
        path: Urls.Management_Tracking,
        hide: !isAdmin,
      },
      {
        name: "Logs",
        iconText: "SI",
        path: Urls.Management_LogsTree,
        hide: !isAdmin,
      },
      {
        name: "Notification",
        path: Urls.Notification,
        iconText: "SU",
        //icon: <Notifications fontSize="small" sx={{ marginLeft: "15px" }} />,
      },
      // { name: "Roles", iconText: "SU", path: Urls.Management_Roles },
      {
        name: "Settings",
        iconText: "SU",
        path: Urls.Management_Settings,
        hide: !isAdmin,
      },
    ],
  },
];
